export enum AccountSetting {
    showTwoFaAlert = 'showTwoFaAlert',
    showMasterPasswordAlert = 'showMasterPasswordAlert',
    centralLPWallet = 'centralLPWallet',
    boarded = 'boarded',
    onbordingComplete = 'onbordingComplete',
    snapPhoto = 'snapPhoto',
    device_list_banner_appearance = 'device_list_banner_appearance',
    securityWasAccessed = 'securityWasAccessed',
}

export interface AccountSettings {
    [AccountSetting.showTwoFaAlert]?: boolean;
    [AccountSetting.showMasterPasswordAlert]?: boolean;
    [AccountSetting.centralLPWallet]?: boolean;
    [AccountSetting.boarded]?: boolean;
    [AccountSetting.onbordingComplete]?: boolean;
    [AccountSetting.snapPhoto]?: boolean;
    [AccountSetting.device_list_banner_appearance]?: string;
    [AccountSetting.securityWasAccessed]?: boolean;
}