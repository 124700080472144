export namespace UiIcon {

    export interface Svg {
        value: string,
        type: Type,
        color?: string,
        size?: number,
        shape?: Shape,
        bg?: string,
        class?: string,
        rotate?: number;
        /**
         *
         * Useed only for icons on UiTag
         * @type {boolean}
         * @memberof Icon
         */
        hasAction?: boolean;
    }

    export interface Img {
        useImgTag?: boolean,
        value?: string,
        class?: string,
        width?: number,
        height?: number,
        alt?: string
    }

    export enum Shape {
        ICON = 'icon',
        CIRCLE = 'circle',
        SQUARE = 'square',
        SQUIRCLE = 'squircle'
    }


    export enum Type {
        BRAND = 'brand',
        GENERIC = 'generic',
        APP = 'app',
        DEVICES = 'devices',
        DEVICES1 = 'devices-v1',
        MENU = 'menu',
        OS = 'os',
        PRIVACY = 'privacy',
        SUBSCRIPTION = 'subscription'
    }

    export const ICON = {
        [Type.GENERIC]: {
            twoFa: '2fa',
            accountPrivacy: 'account-privacy',
            activity: 'activity',
            ads: 'ads',
            advice: 'advice',
            agree: 'agree',
            airBalloon: 'air-balloon',
            androidProtection: 'android-protection',
            arrowDownTriangle: 'arrow-down-triangle',
            arrowIncrase: 'arrow-incrase',
            arrowLeft: 'arrow-left',
            arrowRight: 'arrow-right',
            bitdefenderB: 'bitdefender-b',
            bitdefenderCart: 'bitdefender-cart',
            bitdefenderSquircle: 'bitdefender-squircle',
            blackFriday: 'black-friday',
            blogs: 'blogs',
            bootOptions: 'boot-options',
            brandedCart: 'branded-cart',
            brandedWindow: 'branded-window',
            business: 'business',
            checkOutline: 'check-outline',
            checkmark: 'checkmark',
            circleAttentionFilled: 'circle-attention-filled',
            circleCheckFilled: 'circle-check-filled',
            closeOutline: 'close-outline',
            close: 'close',
            completeProtection: 'complete-protection',
            computerScan: 'computer-scan',
            computerSetting: 'computer-setting',
            computersAndSoftware: 'computersandsoftware',
            connected: 'connected',
            copy: 'copy',
            creditData: 'credit-data',
            dataAnAappControl: 'data-and-app-control',
            dataBreachProtection: 'data-breach-protection',
            day: 'day',
            devices: 'devices',
            dip: 'dip',
            disconnected: 'disconnected',
            diskSpace: 'disk-space',
            download: 'download',
            educationV2: 'education_v2',
            education: 'education',
            emptyPage: 'empty-page',
            entertainment: 'entertainment',
            erase: 'erase',
            errorCircle: 'error-circle',
            errorFilled: 'error-filled',
            error: 'error',
            exclamation: 'exclamation',
            externalLink: 'external-link',
            feature: 'feature',
            filesharing: 'filesharing',
            filmClapper: 'film-clapper',
            financial: 'financial',
            firewall: 'firewall',
            fixApply: 'fix-apply',
            food: 'food',
            gambling: 'gambling',
            government: 'government',
            graph: 'graph',
            halfFull: 'half-full',
            health: 'health',
            hobbies: 'hobbies',
            hosting: 'hosting',
            iconLog: 'icon_log',
            infoCircle: 'info-circle',
            instantMessaging: 'instant-messaging',
            iot: 'iot',
            issuesFlag: 'issues-flag',
            jobSearch: 'job_search',
            key: 'key',
            laptop: 'laptop',
            launch: 'launch',
            limitReached: 'limit_reached',
            localProtection: 'local-protection',
            locatePhone: 'locate-phone',
            locationFeature: 'location_feature',
            locationPin: 'location-pin',
            lockOpen: 'lock-open',
            lock: 'lock',
            magnifyingGlassSearch: 'magnifying-glass-search',
            mail: 'mail',
            malwareScan: 'malware-scan',
            maturecontent: 'maturecontent',
            mediaStreaming: 'media-streaming',
            misc: 'misc',
            mobilePhone: 'mobile-phone',
            monitoring: 'monitoring',
            netWorth: 'net-worth',
            networkProtection: 'network-protection',
            news: 'news',
            noCardRequired: 'no-card-required',
            noScan: 'no-scan',
            notes: 'notes',
            offers: 'offers',
            okPages: 'ok-pages',
            onlineDating: 'online-dating',
            onlinePhotos: 'online-photos',
            onlineProtection: 'online-protection',
            onlineShop: 'online-shop',
            optimizationStart: 'optimization-start',
            optimizer: 'optimizer',
            pageScan: 'page-scan',
            paragraph: 'paragraph',
            parentalProduct: 'parental_product',
            parentalControls: 'parental-controls',
            passwords: 'passwords',
            penSimple: 'pen-simple',
            pen: 'pen',
            pending: 'pending',
            phishing: 'phishing',
            phoneCard: 'phone-card',
            phoneRestricted: 'phone-restricted',
            phoneShielded: 'phone-shielded',
            photoCamera: 'photo-camera',
            plusCircle: 'plus-circle',
            plusSign: 'plus-sign',
            premiumVpn: 'premium-vpn',
            prioritySupport: 'priority-support',
            privateData: 'private-data',
            questionCircle: 'question-circle',
            quickScan: 'quick-scan',
            radioMusic: 'radio_music',
            referralAlt: 'referral-alt',
            referral: 'referral',
            registryKeys: 'registry-keys',
            religious: 'religious',
            remoteAction: 'remote-action',
            resetWifiPassword: 'reset-wifi-password',
            scanReport: 'scan-report',
            schedule: 'schedule',
            searchEngines: 'search-engines',
            search: 'search',
            shieldAttentionFilled: 'shield-attention-filled',
            shieldAttention: 'shield-attention',
            shieldBlocked: 'shield-blocked',
            shieldFilled: 'shield-filled',
            shieldInstall: 'shield-install',
            shieldPlus: 'shield-plus',
            shieldProtectedFilled: 'shield-protected-filled',
            shieldProtected: 'shield-protected',
            shieldSpeech: 'shield-speech',
            shieldTips: 'shield-tips',
            shieldUnprotectedFilled: 'shield-unprotected-filled',
            shieldUnprotected: 'shield-unprotected',
            shieldVpn: 'shield-vpn',
            shield: 'shield',
            socialNetwork: 'social-network',
            sound: 'sound',
            sports: 'sports',
            star: 'star',
            support: 'support',
            systemScan: 'system-scan',
            tabloids: 'tabloids',
            theme: 'theme',
            timeRunning: 'time-running',
            tips: 'tips',
            tools: 'tools',
            travel: 'travel',
            trophy: 'trophy',
            unknown: 'unknown',
            userEdit: 'user-edit',
            userPlus: 'user-plus',
            userSetting: 'user-setting',
            userTime: 'user-time',
            user: 'user',
            videos: 'videos',
            vpn: 'vpn',
            vulnerabilitiesFound: 'vulnerabilities-found',
            webProxy: 'web_proxy',
            webcamMic: 'webcam-mic',
            window: 'window',
            windowsUpdate: 'windows-update',
            xmarkOutline: 'xmark-outline',
            orderIcon: 'order-icon'
        }
    }
}