import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { ValuesService } from "../../values/values.service";
import { UtilsCommonService } from "../../utils/utils-common.service";
import { MessageService } from "./message.service";
import { AdobeDataLayerService } from "./adobe.datalayer.service";
import { ConnectUserInfoService } from '../requests/connect-user-info-service/connect-user-info-service';
@Injectable({ providedIn: 'root' })
export class LanguageService {

    private flag   : string;
    private label  : string;
    private lang   : string;
    private country: string;
    private code   : string;
    private showChangeLanguageModal: boolean;
    private availableLanguages = {};

    constructor(
        private readonly cookieService      : CookieService,
        private readonly valuesService      : ValuesService,
        private readonly utilsService       : UtilsCommonService,
        private readonly translateService   : TranslateService,
        private readonly connectUserInfoService: ConnectUserInfoService,
        public readonly messageService      : MessageService,
        private readonly adobeDataLayerService : AdobeDataLayerService
    ) {
        // do not move in ngOnInit because ot has to run when the service is injected
        this.initService();
    }

    getBrowserLanguage() {
        const languageParams = navigator.language.split("-");

        const language = languageParams.length > 1 ? `${languageParams[0]}_${languageParams[1]}` : languageParams[0];

        if(this.valuesService.languages[language]) {
            return this.valuesService.languages[language].id
        }

        return this.getFromShortLang(languageParams[0]);
    }

    computeShowChangeLanguageModal() {
        let nrOfAvailableLanguages = 0;
        for (const lang in this.valuesService.languages) {
            if (this.valuesService.languages[lang].isAvailable) {
                // aici se copiaza prin referinta pentru ca "availableLanguages" nu o sa se schimbe
                this.availableLanguages[lang] = this.valuesService.languages[lang];
                nrOfAvailableLanguages++;
            }
        }

        // adaug si limba curenta a contului ca sa nu avem confuzii si erori
        if (!this.valuesService.languages?.[this.lang]?.isAvailable) {
            this.availableLanguages[this.lang] = this.valuesService.languages[this.lang];
            nrOfAvailableLanguages++;
        }
        this.showChangeLanguageModal = nrOfAvailableLanguages > 1;
    }

    //*
    //*  Init functions
    //*
    //#region

    /**
     * Initiates service variables: language, flag, country, label.
     * Sets language and default language for translateService.
     */
    initService(): void {
        this.lang = this.getLanguage();

        this.translateService.use(this.utilsService.convertLowerCase(this.lang));
        const searchLang = this.valuesService.languages[this.lang];
        this.flag      = searchLang.flag;
        this.label     = searchLang.code;
        this.code     = searchLang.code;
        this.country   = this.lang.split('_')[1].toLowerCase();

        this.adobeDataLayerService.setLang(this.lang.split('_')[0]);
        this.computeShowChangeLanguageModal();
    }
    //#endregion

    //*
    //*  Getters
    //*
    //#region

    /**
     * Getter for language.
     */
    getLang(): string {
        return this.lang;
    }

    /**
     * Getter for flag
     */
    getFlag() {
        return this.flag;
    }

    /**
     * Getter for label.
     */
    getLabel() {
        return this.label;
    }

    /**
     * Getter for country.
     */
    getCountry() {
        return this.country;
    }

    getAvailableLanguages() {
        return this.availableLanguages;
    }

    getAccountLanguageIndex() {
        let index = 0;
        for (const lang in this.availableLanguages) {
            if (this.lang === lang) {
                break;
            }
            index++;
        }
        return index;
    }

    /**
     * Getter for code.
     */
    getCode() {
        return this.code;
    }

    getShowChangeLanguageModal() {
        return this.showChangeLanguageModal;
    }
    //#endregion

    //*
    //*  Setters
    //*
    //#region

    /**
     * Sets all service variables based on the new language. Writes language cookie if cookies is not set
     * or language has changed. Sets backend account language if language has changed.
     * Sends messsage to all listeners if language has changed.
     *
     * @param lang New language
     */
    set(lang, publicPage?): void {

        try {
            lang = lang.toString();
        } catch {
            return;
        }

        if (!this.valuesService.languages[lang]) {
            return;
        }

        const cookieLangIsSet = this.cookieService.get(this.valuesService.cookieLang) ? true : false;
        const languageIsChanged = (lang !== this.lang);

        if (!languageIsChanged && cookieLangIsSet) {
            return;
        }


        this.cookieService.set(this.valuesService.cookieLang, lang, null, '/', "", true);
        if (languageIsChanged) {
            // re-init the service
            this.initService();
            // sends message to all components
            this.messageService.sendMessage(this.valuesService.events.languageChange, {});
        }

        /**
         * At the begining, when service is loaded, default values are set but cookie is not written
         * After listOwner request is made, backend values reset the service and cookie is finally written
         * So this request should not be made when you get the values from backend, only the service and cookie should change
         */
        if (languageIsChanged && cookieLangIsSet && !publicPage) {
            this.connectUserInfoService.account_profile_set({'lang': lang})
            .subscribe({
                next: (res) => {
                    this.messageService.sendMessage(this.valuesService.events.languageChangedRequestSuccess, {});
                 },
                error: (error) => {
                    this.messageService.sendMessage(this.valuesService.events.languageChangedRequestError, {});
                 }
            })
        }
    }

    setLangForPublicPages(lang) {
        try {
            lang = lang.toString();
        } catch {
            this.set(this.valuesService.defaultLanguage, true);
        }

        if (this.valuesService.languages[lang]) {
            this.set(lang, true);
            return;
        }

        try {
            lang = (window.navigator.language || window.navigator['userLanguage']);
            if (lang.indexOf('-') !== -1) {
                const langs = lang.split('-');
                langs[0] = langs[0].toLowerCase();
                langs[1] = langs[1].toUpperCase();
                let newLang = langs[0] + "_" + langs[1];

                if (this.valuesService.languages[newLang]) {
                    this.set(newLang, true);
                } else {
                    newLang = this.getFromShortLang(langs[0]);
                    this.set(newLang, true);
                }
            } else {
                lang = lang.toLowerCase();
                const newLang = this.getFromShortLang(lang);
                this.set(newLang, true);
            }
        } catch {
            this.set(this.valuesService.defaultLanguage, true);
        }
    }

    getFromShortLang(shortLang) {

        if (this.valuesService.defaultShortLanguage[shortLang]) {
            return this.valuesService.defaultShortLanguage[shortLang];
        }

        for (const lang in this.valuesService.languages) {
            const id = this.valuesService.languages[lang].id;
            if (id.indexOf(shortLang) !== -1) {
                return id;
            }
        }

        return this.valuesService.defaultLanguage;
    }
    /**
     * Set a language to be used in the current page
     * Does not affect global language from cookie
     */
    useCertainLanguage(lang) {
        this.translateService.use(this.utilsService.convertLowerCase(lang));
    }

    /**
     * The language for dpy onboarding should be the one the account uses and not only one of the languages used for the dpy LP
     * Sets the language for translate service to match the one from cookies
     */
    useCookieLanguage() {
        const lang = this.cookieService.get(this.valuesService.cookieLang);
        if (lang && this.valuesService.languages[lang]) {
            this.translateService.use(this.utilsService.convertLowerCase(lang));
        }
    }

    /**
     *
     * Return client language
     * @private
     * @return {*}  {string}
     * @memberof LanguageService
     */
    private getLanguage(): string {
        let lang = this.cookieService.get(this.valuesService.cookieLang);
        let langHasBeenModified = false;

        //If cookie lang dosen't exist and user agent have default lang change the current lang
        if (!this.valuesService.languages[lang]?.isAvailable && navigator.language) {
            lang = this.getBrowserLanguage();
            langHasBeenModified = true;
        }
        // if cookie is not written & user agent lang is not available, variables have a default value based on the default language
        // if default lang is not available take the first lang from the available languages
        if (!this.valuesService.languages[lang]?.isAvailable) {
            if (!this.valuesService.languages?.[this.valuesService.defaultLanguage].isAvailable) {
                const langArr: any[] = Object.values(this.availableLanguages);
                lang = langArr[0].id;
            } else {
                lang = this.valuesService.defaultLanguage;
            }
            langHasBeenModified = true;
        } else {
            this.translateService.setDefaultLang(this.utilsService.convertLowerCase(lang));
        }

        if (langHasBeenModified) {
            this.cookieService.set(this.valuesService.cookieLang, lang, null, '/', "", true);
        }
        return lang;
    }
    //#endregion

}
