import { Injectable } from "@angular/core";
import { Observable, catchError, from, map, of } from "rxjs";
import { DeviceArchitecture, DeviceArchitectureBits, DeviceArchitectureTypes, HighEntropyValues } from "../../../values/values.service";

interface DeviceInfo {
    architecture: DeviceArchitecture;
    platform: string;
    platformVersion: string;
    mobile: string;
}

@Injectable({
    providedIn: 'root'
})

export class DeviceInfoService {
    public deviceInfo: DeviceInfo;

    /**
     * Makes a request to get the device architecture information the app user is currently on, and stores it in a variable.
     * @returns {Observable<any>} device architecture info
     */
    public listDeviceArchitecture(): Observable<any> {
        if (this.deviceInfo) {
            return of(this.deviceInfo);
        }

        const highEntropyValues = [HighEntropyValues.ARCHITECTURE, HighEntropyValues.BITNESS, HighEntropyValues.PLATFORM_VERSION];
        const promise = (<any>navigator).userAgentData?.getHighEntropyValues(highEntropyValues) ?? Promise.resolve();

        return from(promise)
        .pipe(
            map((res: any) => {
                let deviceArch;
                if (res?.architecture === DeviceArchitectureTypes.ARM) {
                    if (res?.bitness === DeviceArchitectureBits.BIT64) {
                        deviceArch = DeviceArchitecture.ARM64;
                    }
                    else if (res?.bitness === DeviceArchitectureBits.BIT32) {
                        deviceArch = DeviceArchitecture.ARM32;
                    }
                } else {
                    deviceArch = res?.architecture;
                }

                this.deviceInfo = {
                    architecture: deviceArch,
                    platform: res?.platform,
                    platformVersion: res?.platformVersion,
                    mobile: res?.mobile
                };
            }),
            catchError(err => {
                return of(true);
            })
        );
    }

    /**
     * Gets the device architecture information the app user is currently on
     * @returns {DeviceArchitecture} device architecture
     */
    public getDeviceArchitecture(): DeviceArchitecture {
        return this.deviceInfo?.architecture;
    }
}
