// External
import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, catchError, takeUntil, last } from 'rxjs/operators';
import { Observable, forkJoin, of, ReplaySubject, Subject, concat } from 'rxjs';

// Internal
import { CommercialidsService } from '../process/commercialids/commercialIds.service';
import { DevicesService } from '../process/devices/devices.service';
import { DOCUMENT } from '@angular/common';
import { HideComponentService } from '../global/hide-component/hide-component.service';
import { IProfilesService } from '../interfaces/iprofiles/iprofiles.service';
import { NotificationsService } from '../process/notifications/notifications.service';
import { OffersService } from '../process/offers/offers.service';
import { PrivacyService } from '../process/privacy/privacy.service';
import { ProfilesService } from '../process/profiles/profiles.service';
import { PushService } from '../core/push.service';
import { ValuesService } from '../../values/values.service';
import { ISubscriptionsService } from '../interfaces/isubscriptions/isubscriptions.service';
import { NavigationService } from '../../navigation/navigation.service';
import { AdobeDataLayerService } from '../core/adobe.datalayer.service';
import { AppsConfigService } from '../../config/apps.config.service';
import { ModalRoutelessService } from '../../components/ui/ui-modal-routeless/modal.routeless.service';
import { SpecialCasesService } from './helpers/special.cases.service';
import { InvitesService } from '../process/subscriptions/invites.service';
import { DeviceInfoService } from '../global/device-info/device-info.service';

@Injectable({
    providedIn: 'root'
})

export class DataResolver implements Resolve<any> {

    private readonly routeResolved: ReplaySubject<boolean> = new ReplaySubject();
    private readonly onDestroy$: Subject<void> = new Subject<void>();

    _resolved = false;
    dataIsReady = false;

    constructor(
        @Inject(DOCUMENT) readonly document: any,
        private readonly commercialidsService: CommercialidsService,
        private readonly devicesService: DevicesService,
        private readonly hideComponentService: HideComponentService,
        private readonly iProfilesService: IProfilesService,
        private readonly notificationsService: NotificationsService,
        private readonly offersService: OffersService,
        private readonly privacyService: PrivacyService,
        private readonly profilesService: ProfilesService,
        private readonly pushService: PushService,
        private readonly valuesService: ValuesService,
        private readonly iSubscriptionsService: ISubscriptionsService,
        private readonly navigationService: NavigationService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly appsConfigService: AppsConfigService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly specialCasesService: SpecialCasesService,
        private readonly invitesService: InvitesService,
        private readonly deviceInfoService: DeviceInfoService
    ) {}

    restartResolverRequests() {
        // ! TopPriority
        return forkJoin([
            this.commercialidsService.list(),
            this.devicesService.listDevices(),
            this.invitesService.list(),
            this.offersService.list().pipe(
                catchError( err => of(err))
            ),
            this.listProvidedIdentityNoError(),
            this.pushService.registerPush(),
            this.deviceInfoService.listDeviceArchitecture()
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(() => {
                this.appsConfigService.showInstallButtonForAllApps();
                this.iSubscriptionsService.createBundlesInterface();
                this.iProfilesService.manage_device_profiles();
                this.notificationsService.sendEventNotifs(true);
                this.adobeDataLayerService.setRedirectStatus(true);
                return of(true);
            }),
            catchError(err => of(err))
        );
    }

    /**
     * Makes listProvidedIdentity request from privacy but only returns a success response
     * so other requests in central do not fail and all pages load
     * @returns Observable
     */
    listProvidedIdentityNoError(): Observable<any> {
        return this.privacyService.listProvidedIdentity().pipe(
            map(() => of(true)),
            catchError(() => of(true))
        );
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const urlProperties = this.navigationService.processUrl(route, state);

        const devicesList = (): Observable<boolean> => {
            return new Observable(subscriber => {
                this.devicesService.listDevices()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        subscriber.next(false);
                        subscriber.complete();
                    },
                    error: () => {
                        subscriber.next(false);
                        subscriber.complete();
                    }
                });
            });
        };

        const specialCasesLogic = new Observable( subscriber => {
            const actionResponses = [];
            concat(
                this.specialCasesService._decideReferral(urlProperties),
                this.specialCasesService._decideMspXspState(urlProperties),
                this.specialCasesService._decideAutomaticRedeemRedirect(urlProperties),
                this.specialCasesService._decideAutomaticTrialRedirect(urlProperties),
                this.specialCasesService._decideAutomaticInviteRedirect(urlProperties),
                this.specialCasesService._decideOnboarding(),
                this.specialCasesService._decideInstallRedirectFromQueryParams(urlProperties),
                this.specialCasesService._decideRedeemRedirectFromQueryParams(urlProperties),
                this.specialCasesService._decideRedirectFromExpiredSubscription(urlProperties),
                this.specialCasesService._decideCongrats(urlProperties)
            )
            .pipe(
                takeUntil(this.onDestroy$),
                map(response => {
                    if (response) {
                        actionResponses.push(response);
                    }
                }),
                last()
            ).subscribe({
                    next: () => {
                    //! Facem ce trebuie cu actiunile
                    // functie care rezolva actiunile in functii de prioritate
                    if (actionResponses.length) {
                        this.modalRoutelessService.open(
                                                        actionResponses[0].name,
                                                        actionResponses[0].containerOptions,
                                                        actionResponses[0].contentOptions,
                                                        false,
                                                        {
                                                            automatic: true
                                                        });
                    }
                    //! Dam drumul la data resolver
                    subscriber.next(true);
                    subscriber.complete();
                }
            });
        });

        return forkJoin([
            concat(
                devicesList(),
                specialCasesLogic
            ).pipe(last()),
            this.restartResolverRequests()
        ])
        .pipe(
            map(() => {
                this.hideComponentService.hideTabsSideMenu(this.profilesService.getOwner());
                this.dataIsReady = true;
                this.routeResolved.complete();
                this._resolved = true;
            }),
            catchError(() => of(true))
        );
    }

    decidePrivacy() {
        if (!this.appsConfigService.showApp(this.valuesService.appDIP)) {
            this.privacyService.updateProvidedIdentity();
        }
    }

    getState(): Observable<boolean> {
        return this.routeResolved.asObservable();
    }
}
